import React, { useState } from "react";
import { useGoogleLogin } from "react-google-login";
import * as CredentialProject from '../../../json/clientId.json'
import logoGoogle from "../../../assets/images/googleLogo.svg"
import { postRegisterGoogle } from "../../../actions/auth";
import { cksClient } from "../../../helper";
import { URL } from "../../../constants/Url";
import { useHistory } from "react-router";
import moment from "moment";
const clientId = CredentialProject.default.web.client_id
const RegisterWithGoogle = () => {
  const [onLoad, setOnload] = useState(false)
  const [textButton, settextButton] = useState('Daftar dengan Google')
  const history = useHistory()
  // var textButton = 'Memuat....'
  const onSuccess = (res) => {
    var profileObj = res.profileObj
    // console.log('loginSuccess currentUser:', res.profileObj)
    var data = new FormData()
    data.set('nama', profileObj.name)
    data.set('google_id', profileObj.googleId)
    data.set('email', profileObj.email)

    postRegisterGoogle(data, res => {
      const { data } = res
      cksClient().set('_authToken', data.data.access_token, {
          path: '/',
          sameSite: 'Lax',
          expired: 21600
      })
      setOnload(false)
      settextButton('Daftar dengan Google')
      return history.push(URL.NEW.INDEX)
    }, err =>{
      setOnload(false)
      settextButton('Daftar dengan Google')
    })
  }

  const onFailure = (res) => {
    setOnload(false)
    settextButton('Daftar dengan Google')
  }

  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId,
    isSignedIn: false,
    accessType: 'offline'
  })

  const signUpData = () => {
    setOnload(true)
    settextButton('Memuat...')
    console.log(onLoad)
    console.log(textButton)
    signIn()
  }

  return (
    <button onClick={signUpData} disabled={onLoad} className="btn-google">
      <img src={logoGoogle}></img>
      {textButton}
    </button>
  )
}

export default RegisterWithGoogle