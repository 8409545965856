import { apiClient } from '../../helper'
import { API } from '../../constants/Url'

import $ from 'jquery'

const get_listProgram = (params, callbkSucccess, callbkError) => {
    apiClient().get(API.PROGRAM_LIST, params).then(resp => {
        if (resp.ok && resp.status === 200) {
            callbkSucccess && callbkSucccess(resp)
        } else {
            console.log(`Get list program has trouble: ${resp.problem}`)
            callbkError && callbkError(resp)
        }
    })
}

const get_listProgramTerdaftar = (params, callbkSucccess, callbkError) => {
    apiClient().get(API.PROGRAM_TERDAFTAR, params).then(resp => {
        if (resp.ok && resp.status === 200) {
            callbkSucccess && callbkSucccess(resp)
        } else {
            console.log(`Get list program terdaftar has trouble: ${resp.problem}`)
            callbkError && callbkError(resp)
        }
    })
}

const get_PembayaranProgram = (params, callbkSucccess, callbkError) => {
    apiClient().get(API.PROGRAM_INFO_INVOICE.replace(':slug', params)).then(resp => {
        if (resp.ok && resp.status === 200) {
            callbkSucccess && callbkSucccess(resp)
        } else {
            console.log(`Get pembayaran program has trouble: ${resp.problem}`)
            callbkError && callbkError(resp)
        }
    })
}

const get_detailProgram = (uniqId, callbkSucccess, callbkError) => {
    apiClient().get(API.PROGRAM_DETAIL.replace(':slug', uniqId)).then(resp => {
        if (resp.ok && resp.status === 200) {
            callbkSucccess && callbkSucccess(resp)
        } else {
            console.log(`Get detail program failed: ${resp.problem}`)
            callbkError && callbkError(resp)
        }
    })
}

const get_detailRegistered = (uniqId, callbkSucccess, callbkError) => {
    apiClient().get(API.PROGRAM_TERDAFTAR_DETAIL.replace(':slug', uniqId)).then(resp => {
        if (resp.ok && resp.status === 200) {
            callbkSucccess && callbkSucccess(resp)
        } else {
            console.log(`Get detail program failed: ${resp.problem}`)
            callbkError && callbkError(resp)
        }
    })
}

const get_questionFrom = (uniqId, callbkSucccess, callbkError) => {
    apiClient().get(API.PROGRAM_QUESTION.replace(':slug', uniqId)).then(resp => {
        if (resp.ok && resp.status === 200) {
            callbkSucccess && callbkSucccess(resp)
        } else {
            console.log(`Get question form list failed: ${resp.problem}`)
            callbkError && callbkError(resp)
        }
    })
}

const get_mouInvoiceFile = (uniqId, callbkSucccess, callbkError) => {
    apiClient().get(API.PROGRAM_MOU_INVOICE.replace(':slug', uniqId)).then(resp => {
        if (resp.ok && resp.status === 200) {
            callbkSucccess && callbkSucccess(resp)
        } else {
            console.log(`Get question mou & invoice list failed: ${resp.problem}`)
            callbkError && callbkError(resp)
        }
    })
}

const post_registerProgram = (uniqId, params, callbkSucccess, callbkError) => {
    const formdata = new FormData()
    var data = []
    $.each(params, (key, value) => {
        const keys =Object.keys(value)
        $.each(keys, (i, e) => {
            data.push({ value:params.data[e].answer, key:e })
        })
    });
    var json = {
        answer: data
    }
    // var json = data.reduce((json, value, key) => { json[key] = value; return json; }, {});
    apiClient().post(`${API.PROGRAM_QUESTION.replace(':slug', uniqId)}/answer`, json).then(resp => {
        if (resp.ok && resp.status === 201) {
            callbkSucccess && callbkSucccess(resp)
        } else {
            console.log(`Post register program has trouble: ${resp.problem}`)
            callbkError && callbkError(resp)
        }
    })
}

const post_programInvoice = (params, callbkSucccess, callbkError) => {
    apiClient().post(API.PROGRAM_INVOICE, params).then(resp => {
        if (resp.ok && resp.status === 201) {
            callbkSucccess && callbkSucccess(resp)
        } else {
            console.log(`Post program invoice has trouble: ${resp.problem}`)
            callbkError && callbkError(resp)
        }
    })
}

const post_programUploadNaskah = (params, callbkSucccess, callbkError) => {
    apiClient().post(API.PROGRAM_UPLOAD_NASKAH, params).then(resp => {
        if (resp.ok && resp.status === 201) {
            callbkSucccess && callbkSucccess(resp)
        } else {
            console.log(`Post program upload naskah has trouble: ${resp.problem}`)
            callbkError && callbkError(resp)
        }
    })
}

const post_programUploadNaskahPaket = (params, callbkSucccess, callbkError) => {
    apiClient().post(API.PROGRAM_UPLOAD_NASKAH_PAKET, params).then(resp => {
        if (resp.ok && resp.status === 201) {
            callbkSucccess && callbkSucccess(resp)
        } else {
            console.log(`Post program upload naskah has trouble: ${resp.problem}`)
            callbkError && callbkError(resp)
        }
    })
}

const getProgramKode = (kode, type = "sosialisator", callbkSucccess, callbkError) => {
    var url = ''
    if (type == "paket") {
        url = API.KODE_CHECK.replace(':kode', kode.kode).replace(':type', type)+'/'+kode.program_id;
    } else {
        url = API.KODE_CHECK.replace(':kode', kode).replace(':type', type);
    }
    apiClient().get(url).then(resp => {
        if (resp.ok && resp.status === 200) {
            callbkSucccess && callbkSucccess(resp)
        } else {
            console.log(`Post program upload naskah has trouble: ${resp.problem}`)
            callbkError && callbkError(resp)
        }
    })
}

const get_ListScript = (params, callbkSucccess, callbkError) => {
    apiClient().post(API.PROGRAM_LIST_NASKAH, params).then(resp => {
        if (resp.ok && resp.status === 201) {
            callbkSucccess && callbkSucccess(resp)
        } else {
            console.log(`get list naskah has trouble: ${resp.problem}`)
            callbkError && callbkError(resp)
        }
    })
}

const post_NaskahRevisi = (params, callbkSucccess, callbkError) => {
    apiClient().post(API.PROGRAM_NASKAH_REVISI, params).then(resp => {
        if (resp.ok && resp.status === 201) {
            callbkSucccess && callbkSucccess(resp)
        } else {
            console.log(`Post revisi naskah has trouble: ${resp.problem}`)
            callbkError && callbkError(resp)
        }
    })
}

const post_NaskahAcc = (params, callbkSucccess, callbkError) => {
    apiClient().post(API.PROGRAM_NASKAH_ACC, params).then(resp => {
        if (resp.ok && resp.status === 201) {
            callbkSucccess && callbkSucccess(resp)
        } else {
            console.log(`Post Acc naskah has trouble: ${resp.problem}`)
            callbkError && callbkError(resp)
        }
    })
}

const post_programInvoiceOngkir = (params, callbkSucccess, callbkError) => {
    apiClient().post(API.PROGRAM_INVOICE_ONGKIR, params).then(resp => {
        if (resp.ok && resp.status === 201) {
            callbkSucccess && callbkSucccess(resp)
        } else {
            console.log(`Post program invoice has trouble: ${resp.problem}`)
            callbkError && callbkError(resp)
        }
    })
}

const post_programTerimaBuku = (params, callbkSucccess, callbkError) => {
    apiClient().post(API.PROGRAM_TERIMA_BUKU, params).then(resp => {
        if (resp.ok && resp.status === 201) {
            callbkSucccess && callbkSucccess(resp)
        } else {
            console.log(`Post program invoice has trouble: ${resp.problem}`)
            callbkError && callbkError(resp)
        }
    })
}

const get_listInvoice = (params, callbkSucccess, callbkError) => {
    apiClient().get(API.PROFILE_LIST_INVOICE, params).then(resp => {
        if (resp.ok && resp.status === 200) {
            callbkSucccess && callbkSucccess(resp)
        } else {
            console.log(`Get list program has trouble: ${resp.problem}`)
            callbkError && callbkError(resp)
        }
    })
}

const getInvoiceOngkir = (id, callbkSucccess, callbkError) => {
    var url = API.DATA_ONGKIR.replace(':slug', id);
    apiClient().get(url).then(resp => {
        if (resp.ok && resp.status === 201) {
            callbkSucccess && callbkSucccess(resp)
        } else {
            console.log(`Get list program has trouble: ${resp.problem}`)
            callbkError && callbkError(resp)
        }
    })
}

//home 
const get_HomeData = (callbkSucccess, callbkError) => {
    apiClient().get(API.HOME).then(resp => {
        if (resp.ok && resp.status === 200) {
            callbkSucccess && callbkSucccess(resp)
        } else {
            console.log(`Get list location has trouble: ${resp.problem}`)
            callbkError && callbkError(resp)
        }
    })
}

const get_VoucherTerbit = (id, callbkSucccess, callbkError) => {
    apiClient().get(API.VOUCHER_TERBIT.replace(':slug', id)).then(resp => {
        if (resp.ok && resp.status === 200) {
            callbkSucccess && callbkSucccess(resp)
        } else {
            console.log(`Get list location has trouble: ${resp.problem}`)
            callbkError && callbkError(resp)
        }
    })
}

const post_VoucherClaim = (params, callbkSucccess, callbkError) => {
    apiClient().post(API.VOUCHER_CLAIM, params).then(resp => {
        if (resp.ok && resp.status === 201) {
            callbkSucccess && callbkSucccess(resp)
        } else {
            console.log(`Get list location has trouble: ${resp.problem}`)
            callbkError && callbkError(resp)
        }
    })
}

const get_VoucherNyalakademi = (id, callbkSucccess, callbkError) => {
    apiClient().get(API.VOUCHER_NYALAKADEMI.replace(':slug', id)).then(resp => {
        if (resp.ok && resp.status === 200) {
            callbkSucccess && callbkSucccess(resp)
        } else {
            console.log(`Get list location has trouble: ${resp.problem}`)
            callbkError && callbkError(resp)
        }
    })
}

const get_DanaOperasional = (id, callbkSucccess, callbkError) => {
    apiClient().get(API.DANA_OPERASIONAL.replace(':slug', id)).then(resp => {
        if (resp.ok && resp.status === 200) {
            callbkSucccess && callbkSucccess(resp)
        } else {
            console.log(`Get list location has trouble: ${resp.problem}`)
            callbkError && callbkError(resp)
        }
    })
}

const get_NotificationList = (params, callbkSucccess, callbkError) => {
    apiClient().get(API.NOTIFICATION, params).then(resp => {
        if (resp.ok && resp.status === 200) {
            callbkSucccess && callbkSucccess(resp)
        } else {
            console.log(`Get list location has trouble: ${resp.problem}`)
            callbkError && callbkError(resp)
        }
    })
}

const post_NotificationRead = (session, callbkSucccess, callbkError) => {
    apiClient().post(API.NOTIFICATION_READ.replace(':slug', session)).then(resp => {
        if (resp.ok && resp.status === 200) {
            callbkSucccess && callbkSucccess(resp)
        } else {
            console.log(`Get list location has trouble: ${resp.problem}`)
            callbkError && callbkError(resp)
        }
    })
}

const post_programUploadWebLite = (params, callbkSucccess, callbkError) => {
    apiClient().post(API.WEBLITE_UPLOAD_TEMPLATE, params).then(resp => {
        if (resp.ok && resp.status === 201) {
            callbkSucccess && callbkSucccess(resp)
        } else {
            console.log(`Post program upload naskah has trouble: ${resp.problem}`)
            callbkError && callbkError(resp)
        }
    })
}

const post_programCountOngkir = (params, callbkSucccess, callbkError) => {
    apiClient().post(API.COUNT_ONGKIR, params).then(resp => {
        if (resp.ok && resp.status === 200) {
            callbkSucccess && callbkSucccess(resp)
        } else {
            console.log(`Post program count ongkir has trouble: ${resp.problem}`)
            callbkError && callbkError(resp)
        }
    })
}


export {
    get_listProgram,
    get_detailProgram,
    get_questionFrom,
    post_registerProgram,
    get_detailRegistered,
    get_listProgramTerdaftar,
    get_mouInvoiceFile,
    post_programInvoice,
    post_programUploadNaskah,
    post_programUploadNaskahPaket,
    get_ListScript,
    post_NaskahRevisi,
    post_NaskahAcc,
    post_programInvoiceOngkir,
    post_programTerimaBuku,
    get_listInvoice,
    getInvoiceOngkir,
    get_PembayaranProgram,
    getProgramKode,
    //home
    get_HomeData,
    get_VoucherTerbit,
    post_VoucherClaim,
    get_VoucherNyalakademi,
    get_DanaOperasional,
    get_NotificationList,
    post_NotificationRead,
    post_programUploadWebLite,
    post_programCountOngkir
}
