import React from "react"
import "../../assets/css/main.scss"
import logoNyalanesia from "../../assets/images/nyalanesia_bg.png"
import DipsyLogo from "../../assets/images/dipsy_logo.png"
import { Button, Container, Form } from 'react-bootstrap'
import { Formik } from 'formik'
import { initFormLogin, valid_formSignIn } from './common/const'
import { URL } from "../../constants/Url"
import { serializeForm } from '../../helper'
import { post_loginUser } from '../../actions/auth'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { cksClient } from '../../helper'
import { CustomInfoMessage } from "../../components/custom"
import { NavLink as RouterLink } from 'react-router-dom'
import LoginWithGoogle from "./components/LoginGoogle"
import moment from "moment"
class Login extends React.Component {
    constructor(props) {
        super(props)
        this.state = { 
            formLogin: {
                ...initFormLogin,
                isLoading: false,
            },
            info: {
                isShow: null,
                infoVariant: null,
                infoBody: null,
            },
            showPassword: [
                false
            ]
         }
    }

    click_buttonSubmit = (values) => {
        const { state } = this
        const formData = serializeForm(initFormLogin, values, 'form-data')
        const formLogin = {...state.formLogin}
        
        formLogin.isLoading = true
        this.setState({
            formLogin
        }, () => {
            post_loginUser(formData, 
                resp => {
                    const { data } = resp.data

                    formLogin.isLoading = false
                    this.setState({
                        formLogin
                    })
                    cksClient().set('_authToken', data.access_token, {
                        path: '/',
                        sameSite: 'Lax',
                        maxAge: 21600
                    })
                    return this.props.history.push(URL.NEW.INDEX)
                },
    
                () => {
                    formLogin.isLoading = false
                    this.set_toggleInfo(true, 'danger', 'Please check your email/password again')
                    this.setState({
                        formLogin
                    })
                }
            ) 
        })
    }

    set_toggleInfo = (nextState, infoVariant, infoBody) => {
        this.setState({
            info: {
                isShow: nextState,
                infoVariant: infoVariant,
                infoBody: infoBody
            }
        })
    }

    loginGoogleFailure = (err) => {
        this.set_toggleInfo(true, 'danger', 'Please check your email/password again')
    }

    showingPassword = (key) => {
        const { state } = this
        var showing = state.showPassword
        showing[key] = !showing[key]
        this.setState({
            showPassword: showing
        })
    }

    render() {
        const { state } = this

        return (
            <div className="welcome-page wrapper">
                <Container>
                    <div className="welcome-page-body mb-0 pb-0">
                        <img src={logoNyalanesia} alt="logoNyalanesia" />
                        <p className="welcome-page-title-label">Selamat datang di</p>
                        <img src={DipsyLogo} alt="dispyLogo" className="dipsy-logo" />
                    </div>
                    <div className="welcome-page-title-footer">
                        <CustomInfoMessage 
                            show={state.info.isShow}
                            variant={state.info.infoVariant}
                            header={state.info.infoVariant}
                            body={state.info.infoBody}
                        />
                        <Formik
                            initialValues={state.formLogin}
                            validationSchema={valid_formSignIn}
                            onSubmit={this.click_buttonSubmit}
                            validateOnBlur={false}
                            validateOnChange={false}
                            >
                            {formikProps => (
                                <Form className="LoginForm">
                                    <Form.Group>
                                        <Form.Label>Email Address</Form.Label>
                                        <Form.Control 
                                            error={formikProps.errors.email}
                                            type="email" 
                                            name="email"
                                            onChange={formikProps.handleChange}
                                            value={formikProps.values.email}
                                            isInvalid={!!formikProps.errors.email}
                                        />

                                        {formikProps.errors.email && (
                                            <Form.Control.Feedback type="invalid">
                                                {formikProps.errors.email}
                                            </Form.Control.Feedback>
                                        )}
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>Password</Form.Label>
                                        <div class="position-relative">
                                            <Form.Control 
                                                error={formikProps.errors.password}
                                                type={state.showPassword[0] ? 'text': 'password'} 
                                                name="password"
                                                onChange={formikProps.handleChange}
                                                value={formikProps.values.password}
                                                isInvalid={!!formikProps.errors.password}
                                            />
                                            <span className="form-icon" onClick={(e) => this.showingPassword(0)}><FontAwesomeIcon icon={(state.showPassword[0]) ? faEyeSlash : faEye} /></span>
                                        </div>

                                        {formikProps.errors.password && (
                                            <Form.Control.Feedback type="invalid">
                                                {formikProps.errors.password}
                                            </Form.Control.Feedback>
                                        )}
                                    </Form.Group>

                                    <div className="d-flex justify-content-between align-items">
                                        <Form.Check type="checkbox">
                                            <Form.Check.Input type="checkbox" />
                                            <Form.Check.Label>Ingatkan Saya</Form.Check.Label>
                                        </Form.Check>
                                        <RouterLink className="forgot-password-link" to={URL.FORGOT_PASSWORD}>Lupa Password</RouterLink>
                                    </div>

                                    <Form.Group className="form-footer pt-5">
                                    <div className="button-footer">
                                        <Button 
                                            type="submit"
                                            onClick={formikProps.handleSubmit}
                                            disabled={state.formLogin.isLoading}>
                                            {state.formLogin.isLoading ? <FontAwesomeIcon icon={faSpinner} spin />  :'MASUK'}
                                        </Button>
                                        <p>belum memiliki akun? <br /><Button variant="link" onClick={() => this.props.history.push(URL.REGISTER)}>Daftar</Button></p>
                                    </div>
                                        <div className="googleContainer">
                                        <div className="text-center label-text mt-3 mb-3"><span></span><div>atau</div><span></span></div>
                                            <LoginWithGoogle onError={this.loginGoogleFailure} />
                                        </div>
                                    </Form.Group>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </Container>
            </div>
        )
    }
}

export default Login