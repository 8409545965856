import axios from 'axios'
import Cookies from 'universal-cookie'
import { create } from 'apisauce'

const apiClient = (withBearer = true, type = 'apisouce') => {
    const _url = process.env.REACT_APP_GMB_API
    const _tkn = cksClient().get('_authToken')
    const options = {
        baseURL: _url,
        headers: {
            'Content-Type': 'application/json',
        },
        timeout: 50000,
    }

    if (withBearer) {
        options.headers = {
            ...options.headers,
            'Authorization': `Bearer ${_tkn}`,
        }
    }

    const client = axios.create(options)
    client.interceptors.request.use(
        requestConfig => requestConfig,
        requestError => {
            return Promise.reject(requestError)
        },
    )

    client.interceptors.response.use(
        response => Promise.resolve(response),
        error => {
            if (error.response) {
                const { status } = error.response

                if (status === 403) {
                    console.log('Global api check - Forbidden Access')
                }
            }

            return Promise.reject(error)
        }
    )

    if (type === 'apisouce') {
        return create({ axiosInstance: client })
    }

    return client
}

const serializeForm = (initial, formData, type='json') => {
    const fixFormData = type === 'form-data' ? new FormData() : {}

    for (let key in initial) {
        if (initial.hasOwnProperty(key) && formData.hasOwnProperty(key)) {
            if (formData[key] !== null && formData[key] !== '' && formData[key] !== undefined) {
                if (type === 'json') {
                    fixFormData[key] = formData[key]
                } else {
                    fixFormData.append(key, formData[key])
                }
            }
        }
    }

    return fixFormData
}

const cksClient = () => {
    const cksApp = new Cookies()

    return cksApp
}

// const refreshTokenSetup =  (res) => {
//     var refresingTiming = (res.tokenObj.expired_in || 3600 - 5 * 60) * 1000;

//     const refreshToken = async () => {
//         const newAuthRes = await res
//     }
// }

export {
    apiClient,
    cksClient,
    serializeForm,
}

