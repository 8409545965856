import React from "react"
import Logo from "../../assets/images/nav_logo.png"
import CaretUp from "../../assets/images/icon/caret_up.svg"
import { Navbar, Nav, Dropdown } from "react-bootstrap"
import Badge from 'react-bootstrap/Badge'
import { URL } from "../../constants/Url"
import { NavLink as RouterLink, Route, Switch, Redirect } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import PrivateRoute from "../../components/router/privateRoute"
// import PublicRoute from "../../components/router/publicRoute"
import { removeToken } from '../../components/router/common'
import '../../assets/css/_newhome.scss'
import { get_NotificationList, post_NotificationRead } from "../../actions/program"
import moment from "moment"
import 'moment/locale/id'
import $ from "jquery"
import ShowingNotif from "./showingNotif"
import Skeleton from "react-loading-skeleton"

const ProgramList = React.lazy(() => import('../program'))
const ProgramDetail = React.lazy(() => import('../program/Detail'))
const ProgramForm = React.lazy(() => import('../program/Form'))
const ProgramRegistered = React.lazy(() => import('../program/List'))
const ProgramRegisteredDetail = React.lazy(() => import('../program/RegisteredDetail'))
const ProgramRevisiNaskah = React.lazy(() => import('../program/revisiNaskah'))
const ProgramMouInvoice = React.lazy(() => import('../program/MouInvoice'))
const ProgramInvoiceForm = React.lazy(() => import('../program/InvoiceForm'))
const ProgramUploadForm = React.lazy(() => import('../program/UploadNaskahForm'))
const ProgramUploadFormPaket = React.lazy(() => import('../program/UploadNaskahFormPaket'))
const ProgramUpselling = React.lazy(() => import('../program/Upselling'))
const ProgramUpsellingForm = React.lazy(() => import('../program/UpsellingForm'))
const Profile = React.lazy(() => import('../profile'))
const ProfileChangePassword = React.lazy(() => import('../profile/ChangePassword'))
const ProgramListScript = React.lazy(() => import('../program/Script'))
const OngkirKonfirmasi = React.lazy(() => import('../program/OngkirKonfirmasi'))
const ProfileUpdate = React.lazy(() => import('../profile/ProfileUpdate'))
const PKSList = React.lazy(() => import('../profile/PksList'))
const NaskahList = React.lazy(() => import('../profile/Naskah'))
const VerifikasiList = React.lazy(() => import('../profile/Verifikasi'))
const ProsesList = React.lazy(() => import('../profile/Proses'))
const PengirimList = React.lazy(() => import('../profile/Pengirim'))
//home new
const HomePage = React.lazy(() => import("./Home"))
const NotifPage = React.lazy(() => import("./Notification"))
const ProgramTerdaftar = React.lazy(() => import('../program/new/Terdaftar'))
const ProgramTerdaftardetail = React.lazy(() => import("../program/new/TerdaftarDetail"))
const ProgramDanaOps = React.lazy(() => import("../program/new/DanaOperasional"))
const ProgramVoucherTerbit = React.lazy(() => import("../program/new/VoucherPenerbitan"))
const ProgramVoucherNyala = React.lazy(() => import("../program/new/VoucherNyala"))
const ProgramWebsiteLiterasi = React.lazy(() => import("../program/new/WebsiteLiterasi"))
const ProgramSertfikat = React.lazy(() => import("../program/new/Sertifikat"))
const RegisterPaket = React.lazy(() => import("../program/new/RegisterPaket"))
const RegisterNonPaket = React.lazy(() => import("../program/new/RegisterNonPaket"))
const ListPayment = React.lazy(() => import("../program/new/WaitingPayment"))

class MainContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            isCollapse: false,
            isLoading: true,
            unRead: 0,
            listNotif: [],
            nameUser: '',
            isShowNotif: false,
            dataNotif: null,
        }
    }

    componentDidMount () {
        this.getNotif()
    }

    getNotif = () => {
        get_NotificationList({
            is_paging: false,
        }, (resp) => {
            var nameUser = resp.data.nameUser
            nameUser = nameUser.toString().split(' ')[0]
            this.setState({
                isLoading: false,
                unRead: resp.data.unRead,
                listNotif: resp.data.list,
                nameUser: nameUser
            })
        })
    }

    click_linkLogout = () => {
        const { props } = this

        removeToken()
        props.history.replace(URL.LOGIN)
    }

    click_toggleButton = () => {
        const { state } = this

        this.setState({
            isCollapse: !state.isCollapse
        })
    }

    click_toggleButtonClose = (nextValue) => {
        this.setState({
            isCollapse: nextValue
        })
    }

    convertTime = (value) => {
        var date = moment(value)
        var today = moment()

        if (today.diff(date, 'minute') <= 60) {
            return `${today.diff(date, 'minute')} menit yang lalu`
        } else if (today.diff(date, 'minute') >= 60 && today.diff(date, 'days') == 0) {
            return `${date.format('HH:mm')} WIB`
        } else {
            return `${date.format('DD MMM YYYY')}`
        }
    }

    open_Notif = (index, session, title, notif) => {
        const {state} = this
        var listNotif = state.listNotif
        var unRead = state.unRead
        this.setState({
            isShowNotif: true,
            dataNotif: {
                session: session,
                title: title, 
                msg: notif
            }
        }, () => {
            post_NotificationRead(session)
            unRead -= 1
            listNotif[index].is_read = true
            $('.navbar-header').click()
            this.setState({
                listNotif: listNotif,
                unRead: unRead
            })
        })
    }

    goToNotif = () => {
        $('.navbar-header').click()
        this.props.history.push(URL.NEW.NOTIFICATION)
    }

    render() {
        const { state } = this

        return (
            <div className="wrapper">
                <section className="navprofile">
                    <div className="nav-profile">
                        <img src="/assets/img/profile-def.png" alt="" />
                        <span>Hai, <b>{state.nameUser}</b></span>
                    </div>
                    <Dropdown className="notif-area" menuAlign="right" drop={'left'}>
                        <Dropdown.Toggle style={{backgroundColor: 'transparent'}} id="dropdown-basic">
                            <div className="nav-notif">
                                <img src="/assets/img/notif.png" alt="notif" />
                                {state.unRead > 0 ? 
                                    <>
                                        <Badge variant="danger">{(state.unRead <= 9) ? state.unRead : '+9'}</Badge>                                    
                                    </>
                                    : ''
                                }
                            </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <div className="notif-list">
                                {state.isLoading ? 
                                    <>
                                        <Skeleton count={3} height="60px"></Skeleton>
                                    </>
                                    :
                                    state.listNotif.length > 0 ?
                                    state.listNotif.map((value, index) => {
                                        return (
                                            <div className={(value.is_read == "true" || value.is_read === true) ? 'notif-item read' : 'notif-item'}  data-id={'notif-'+value.session} onClick={e => this.open_Notif(index, value.session, value.title, value.msg)}>
                                                <div className="notif-header">
                                                    <div className="notif-title">{value.title}</div>
                                                    <div className="notif-time">{this.convertTime(value.created_at)}</div>
                                                </div>
                                                <div className="notif-body" dangerouslySetInnerHTML={{__html: value.msg}}></div>
                                            </div>
                                        )
                                    })
                                    : 
                                    <>
                                    <div class="p-3 my-2 text-center" style={{fontSize: '13px'}}>Tidak ada notif terbaru</div>
                                    </>
                                }
                                {state.listNotif.length > 0 ?
                                    <>
                                        <div className="showmore-notif" onClick={e => this.goToNotif()}>Lihat Semua</div>
                                    </>
                                    : ''
                                }
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                </section>
                <ShowingNotif onClose={() => {
                    this.setState({
                        isShowNotif: false
                    })
                }} notif={state.dataNotif} showing={state.isShowNotif}></ShowingNotif>
                <Navbar bg="red" expand="lg" className="navbar-header">
                    {/* <RouterLink to={URL.PROGRAM_REGISTERED} onClick={() => this.click_toggleButtonClose(false)}>
                        <img src={Logo} alt="gmb logo" className="bg-brand"></img>
                    </RouterLink> */}
                    <Navbar.Toggle 
                        aria-controls="basic-navbar-nav"
                        onClick={() => this.click_toggleButton()} />
                    <div className="navbar-top justify-content-start">
                        <RouterLink to={URL.NEW.INDEX} onClick={() => this.click_toggleButtonClose(false)}>Beranda</RouterLink>      

                        <RouterLink to={URL.NEW.PAYMENT} onClick={() => this.click_toggleButtonClose(false)}>Pembayaran</RouterLink>                        

                        <RouterLink to={URL.NEW.PROGRAM_REGISTERED} onClick={() => this.click_toggleButtonClose(false)}>Program Terdaftar</RouterLink>                        
                        
                    </div>
                    <Navbar className={state.isCollapse ? `custom-collapse show` : `custom-collapse not-show`} id="basic-navbar-nav">
                        <Nav>
                            {/* <div className="navbar-caret" onClick={() => this.click_toggleButton()}>
                                <img src={CaretUp} alt="caret-up"></img>
                            </div> */}
                            <RouterLink to={URL.PROFILE} onClick={() => this.click_toggleButtonClose(false)}>Akun Saya</RouterLink>
                            <RouterLink to={URL.NEW.INDEX} onClick={() => this.click_toggleButtonClose(false)}>Beranda</RouterLink>
                            <RouterLink to={URL.NEW.PROGRAM_REGISTERED} onClick={() => this.click_toggleButtonClose(false)}>Program Diikuti</RouterLink>
                            <RouterLink to={URL.NEW.PAYMENT} onClick={() => this.click_toggleButtonClose(false)}>Pembayaran</RouterLink>

                            <Nav.Link className="last" onClick={() => this.click_linkLogout()}>Keluar</Nav.Link>
                        </Nav>
                    </Navbar>
                </Navbar>

                <section className="main-content pb-2">
                    <React.Suspense
                        fallback={<p>
                            <FontAwesomeIcon icon={faSpinner} spin /> 
                            <span>Loading...</span>
                        </p>}>
                        <Switch>
                            <Route path="/app/" render={() => <Redirect to={URL.NEW.INDEX} />} exact />
                            <PrivateRoute path={URL.PROGRAM} component={ProgramList} exact />
                            <PrivateRoute path={URL.PROGRAM_DETAIL} component={ProgramDetail} exact />
                            <PrivateRoute path={URL.PROGRAM_FORM} component={ProgramForm} exact />
                            <PrivateRoute path={URL.PROGRAM_REGISTERED} component={ProgramRegistered} exact />
                            <PrivateRoute path={URL.PROGRAM_REGISTERED_DETAIL} component={ProgramRegisteredDetail} exact />
                            <PrivateRoute path={URL.PROGRAM_INVOICE} component={ProgramInvoiceForm} exact />
                            <PrivateRoute path={URL.PROGRAM_MOU_INVOICE} component={ProgramMouInvoice} exact />
                            <PrivateRoute path={URL.PROGRAM_UPLOAD_NASKAH} component={ProgramUploadForm} exact />
                            <PrivateRoute path={URL.PROGRAM_UPLOAD_NASKAH_PAKET} component={ProgramUploadFormPaket} exact />
                            <PrivateRoute path={URL.PROGRAM_UPSELLING} component={ProgramUpselling} exact />
                            <PrivateRoute path={URL.PROGRAM_UPSELLING_FORM} component={ProgramUpsellingForm} exact />
                            <PrivateRoute path={URL.PROGRAM_SCRIPT_REVISI} component={ProgramRevisiNaskah} exact />
                            <PrivateRoute path={URL.PROFILE} component={Profile} exact />
                            <PrivateRoute path={URL.PROFILE_CHANGE_PASSWORD} component={ProfileChangePassword} exact />
                            <PrivateRoute path={URL.PROGRAM_SCRIPT} component={ProgramListScript} exact />
                            <PrivateRoute path={URL.PROGRAM_ONGKIR_KONFIRMASI} component={OngkirKonfirmasi} exact />
                            <PrivateRoute path={URL.PROFILE_PKS_INVOICE} component={PKSList} exact />
                            <PrivateRoute path={URL.PROFILE_PKS_NASKAH} component={NaskahList} exact />
                            <PrivateRoute path={URL.PROFILE_PKS_VERIFIKASI} component={VerifikasiList} exact />
                            <PrivateRoute path={URL.PROFILE_PKS_PROSES} component={ProsesList} exact />
                            <PrivateRoute path={URL.PROFILE_PKS_PENGIRIM} component={PengirimList} exact />
                            <PrivateRoute path={URL.PROFILE_UPDATE} component={ProfileUpdate} exact />
                            {/* New Home */}
                            <PrivateRoute path={URL.NEW.INDEX} component={HomePage} exact />
                            <PrivateRoute path={URL.NEW.NOTIFICATION} component={NotifPage} exact />
                            <PrivateRoute path={URL.NEW.PROGRAM_REGISTERED} component={ProgramTerdaftar} exact />
                            <PrivateRoute path={URL.NEW.PROGRAM_REGISTERED_DETAIL} component={ProgramTerdaftardetail} exact />
                            <PrivateRoute path={URL.NEW.PROGRAM_DANA_OPS} component={ProgramDanaOps} exact />
                            <PrivateRoute path={URL.NEW.PROGRAM_VOUCHER_TERBIT} component={ProgramVoucherTerbit} exact />
                            <PrivateRoute path={URL.NEW.PROGRAM_VOUCHER_NYALA} component={ProgramVoucherNyala} exact />
                            <PrivateRoute path={URL.NEW.PROGRAM_WEBSITE_LITERASI} component={ProgramWebsiteLiterasi} exact />
                            <PrivateRoute path={URL.NEW.PROGRAM_SERTIFIKAT} component={ProgramSertfikat} exact />
                            <PrivateRoute path={URL.NEW.REGISTER_PAKET} component={RegisterPaket} exact />
                            <PrivateRoute path={URL.NEW.REGISTER_NON_PAKET} component={RegisterNonPaket} exact />
                            <PrivateRoute path={URL.NEW.PAYMENT} component={ListPayment} exact />
                        </Switch>
                    </React.Suspense>
                </section>
            </div>
        )
    }
}

export default MainContainer