import React from "react"
import { Formik } from 'formik'
import { URL } from "../../constants/Url"
import { Alert, Button, Container, Form, FormControl, InputGroup } from "react-bootstrap"
import { get_listProgramTerdaftar, get_PembayaranProgram, post_programInvoice } from '../../actions/program'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { serializeForm } from '../../helper'
import bsCustomFileInput from 'bs-custom-file-input'
import $ from 'jquery'
import { CustomInfoMessage } from "../../components/custom"
import equal from 'fast-deep-equal'

class ShowingNotif extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            isPaket: false,
            info: {
                isShow: null,
                infoVariant: 'danger',
                infoBody: null,
            },
            showingExpand: false,
            dataNotif: [],
            info: {
              isShow: null,
              infoVariant: 'danger',
              infoBody: null,
            },
        }
    }

    updateExpand () {
      const { props, state } = this
      var showingExpand = (props.showing === true) ? true : false;
      this.setState({
        showingExpand: showingExpand,
        dataNotif: props.notif
      })
      if (showingExpand) {
        $('body').toggleClass('notoverflow')
        // this.api_getListProgramTerdaftar()
      }
    }

    componentDidUpdate(prevProps) {
      if(!equal(this.props.showing, prevProps.showing)){
        this.updateExpand()
      }
    }

    componentDidMount() {
        // this.api_getListProgramTerdaftar()
    }


    set_toggleInfo = (nextState, infoVariant, infoBody) => {
        this.setState({
            info: {
                isShow: nextState,
                infoVariant: infoVariant,
                infoBody: infoBody
            }
        })
    }

    CollapseCard = () => {
      const {props} = this
      $('body').removeClass('notoverflow')
      props.onClose()
    }
    formatMoney = (x) => {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    render() {
        const { state } = this
        if(state.showingExpand) {
          return (
            <div className={(state.showingExpand) ? 'card-expand show' : 'card-expand'}>
              <div className="card-expand-wrapper">
                <Container className="p-0">
                    <div className="custom-card p-3">
                    <div className="form-header-list justify-content-end">
                      <div className="form-collapse" title="Tutup" onClick={(e) => this.CollapseCard()}></div>
                    </div>
                        <h5 style={{color: 'black','fontSize': '18px'}}>{state.dataNotif.title}</h5>
                        <div style={{fontSize: '13px', marginTop: '1rem'}} dangerouslySetInnerHTML={{__html: state.dataNotif.msg}}></div>
                    </div>
                </Container>
              </div>
            </div>
          )
        } else {
          return (
            <div></div>
          )
        }
    }
}

export default ShowingNotif